import styled from "styled-components";


const DivInputRadio=styled.div`
    width:100% ;

    form{
        display: flex;
        justify-content: center;
    }
    p{
        text-align:center ;
        color:var(--primary);
        font-weight:bold ;
        text-transform:capitalize ;
    }
    div{
        display:flex ;
        justify-content:center ;
    }
`

export {DivInputRadio}