import { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, onSnapshot, query  } from "firebase/firestore";




const useGetTests = () => {

    const [ tests, setTests]=useState([]);

    useEffect(()=>{
        const consulta=query(
            collection(db, 'tests')
        )
       
            const unsub = onSnapshot(consulta, (snapshot) => {
                    const dataSnapShot=snapshot.docs.map((test)=>{
                      
                        return {...test.data(),idTest:test.id}
                            
                        
                    });
                    setTests(dataSnapShot) ;
            });
          
            
            return unsub;
           
    },[])
    return [tests];
}
 
export default useGetTests;