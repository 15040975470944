import System from '../../componentes/sistema';
import { ClientesStyle, DivTableClientes } from './style';
import Row from '../../componentes/Row';
import Button from '../../componentes/button';
import { useState } from 'react';

import Tabla from '../../componentes/tabla';
import ModalAgregarTest from './modalAgregarTest';

import useGetTests from '../../hooks/useGetTests';
import ModalEditarTest from './modalEditarTest.js';


const TestDelSueno = () => {
    const [tests]=useGetTests();
    const [viewModal, setViewModal] = useState(false);
    const [idEditar, SetidEditar] = useState('');
    const [viewModalEditar, setViewModalEditar] = useState(false);
    
    const modalEditar=(id)=>{
            
        SetidEditar(id);
        setViewModalEditar(true);
        
    }
    
  
    return (
        <>
        <ModalAgregarTest viewModal={viewModal} setViewModal={setViewModal} dataTest={tests}></ModalAgregarTest>
        
             <ModalEditarTest viewModalEditar={viewModalEditar} setViewModalEditar={setViewModalEditar} idEditar={idEditar}></ModalEditarTest>
            
        
     
        <System>
            <ClientesStyle>
                <Row justifyContent={'end'} alignItems={'end'} w={95} margin={'auto'} padding={'2em'}>
                   
                    <Button onClick={()=>{setViewModal(true)}} margin={0}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Agregar
                    </Button>
                </Row>
                <DivTableClientes>
                {tests.length > 0 ?

                <Tabla>
                    <>
                        <thead>
                            <tr>
                                <th>Nombre </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <th>Acciones </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tests.map((testItem)=>{
                               
                                return(
                                    <tr key={testItem.idTest}>
                                    <td>{testItem.nombrePaciente }</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    
                                    
                                    <td>
                                        <div >
                                            <button onClick={()=>{modalEditar(testItem.idTest)}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"></path></svg>
                                            </button>
                                       
                                        </div>
                                    </td>
                                </tr>
                                )
                            })}
                           
                        </tbody>
                    </>
            </Tabla>
                    : <></>}
                </DivTableClientes>


            </ClientesStyle>
        </System>
        
        
        </>
    );
}

export default TestDelSueno;