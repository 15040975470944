import { useState } from "react";
import Form from "../../../componentes/form";
import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Button from "../../../componentes/button";
import Modal from "../../../componentes/modal";
import Alert from "../../../componentes/alert";
import crearPaciente from "../../../firebase/crearPaciente";




const ModalAgregarPaciente = ({viewModal, setViewModal}) => {
    
   
    const [estadoAlert, setEstadoAlert] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState({});
    const [nombre, setNombre] = useState('');
    const [rut, setRut] = useState('');
    const [correo, setCorreo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [direccion, setDireccion] = useState('');
    



    const registrarPaciente=async(e)=>{
        e.preventDefault();
        const expresionRegular=/[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
        if(correo!=='' && !expresionRegular.test(correo)){
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'Correo no válido',
                type:'error'
            })
            return;
        }
      
       
        if (nombre==='') {
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'Nombre no puede quedar vacío',
                type:'error'
            })
           
            return;
        }
       
       
       
        try {
            
            
            crearPaciente({
                nombre:nombre,
                rut:rut,
                correo:correo,
                telefono:telefono,
                direccion:direccion,
                
                
            });
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'El Cliente se agregó correctamente',
                type:'success'
            })
            setViewModal(false);
            
        } catch (err) {
            setEstadoAlert(true);

           let mensaje;
            switch (err.code) {
                
                default:
                    mensaje="Ocurrió un error"
                    
                    break;
            }
            setMensajeAlert({
                mensajeAlert:mensaje,
                type:'error'
            })
        }
    }
    return ( 
        <>
        
        <Alert
            mensajeAlert={mensajeAlert.mensajeAlert}
            type={mensajeAlert.type}
            estadoAlert={estadoAlert}
            setEstadoAlert={setEstadoAlert}
            
            ></Alert>
        
        <Modal titulo={"Agregar nuevo Paciente"} estadoModal={viewModal} cambiarEstado={setViewModal} tamano={40}>
        <Form onSubmit={registrarPaciente}>

            <Row>

                <Column w={90} margin={'auto'}>

                    <label>Nombre</label>
                    <input 
                    type="text" 
                    placeholder="Nombre"
                    value={nombre}
                    onChange={(e)=>{setNombre(e.target.value)}}
                    ></input>
                     


        <label>RUT</label>
        <input 
        type="text" 
        placeholder="RUT"
        value={rut}
        onChange={(e)=>{setRut(e.target.value)}}
        ></input>
        

        

                    <label>Correo</label>
                    <input 
                    type="email" 
                    placeholder="Correo"
                    value={correo}
                    onChange={(e)=>{setCorreo(e.target.value)}}
                    ></input>

                    <label>Telefono</label>
                    <input 
                    type="text" 
                    placeholder="Teléfono"
                    value={telefono}
                    onChange={(e)=>{setTelefono(e.target.value)}}
                    ></input>

                    <label>Dirección</label>
                    <input 
                    type="text" 
                    placeholder="Direccion"
                    value={direccion}
                    onChange={(e)=>{setDireccion(e.target.value)}}
                    ></input>

                   
                  

                    <Button size={'smallBlock'} margin={'2em 0'}>Registrar</Button>
                </Column>


            </Row>

        </Form>
    </Modal>
    </>
     );
}
 
export default ModalAgregarPaciente;