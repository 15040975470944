import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import {doc, getDoc} from "firebase/firestore";

import { useNavigate } from "react-router-dom";

const useGetPacientePorId  = (id) => {
   
    const [paciente, setPaciente]=useState('');
    const navigate=useNavigate();

    useEffect(()=>{
        if (id!=='') {
        const getUsuario=async()=>{
            const documento= await getDoc(doc(db,'pacientes',id));
            if (documento.exists) {
                setPaciente(documento)
            } else {
                navigate('/usuarios');
            }
        }
        getUsuario()
    }
    },[id,navigate])
    return [paciente];
}
 
export default useGetPacientePorId ;