import { useState,useEffect } from "react";
import Form from "../../../componentes/form";
import Row from "../../../componentes/Row";
import Column from "../../../componentes/column";
import Button from "../../../componentes/button";
import Modal from "../../../componentes/modal";
import Alert from "../../../componentes/alert";
import ItemTest from "../../../componentes/itemTest";
import useGetPacientes from "../../../hooks/useGetPacientes";
import crearTest from "../../../firebase/crearTest";




const ModalAgregarTest = ({viewModal, setViewModal}) => {
    const [pacientes]=useGetPacientes()
    const [selectPaciente, setSelectPaciente] = useState('');
    const [estadoAlert, setEstadoAlert] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState({});
    const [respuesta1, setrespuesta1]=useState(1);
    const [respuesta2, setrespuesta2]=useState(1);
    const [respuesta3, setrespuesta3]=useState(1);
    const [respuesta4, setrespuesta4]=useState(1);
    const [respuesta5, setrespuesta5]=useState(1);
    const [respuesta6, setrespuesta6]=useState(1);
    const [respuesta7, setrespuesta7]=useState(1);
    const [respuesta8, setrespuesta8]=useState(1);
    const [respuesta9, setrespuesta9]=useState(1);
    const [respuesta10, setrespuesta10]=useState(1);
    const [respuesta11, setrespuesta11]=useState(1);
    const [respuesta12, setrespuesta12]=useState(1);
    const [respuesta13, setrespuesta13]=useState(1);
    const [respuesta14, setrespuesta14]=useState(1);
    const [respuesta15, setrespuesta15]=useState(1);
    const [respuesta16, setrespuesta16]=useState(1);
    const [respuesta17, setrespuesta17]=useState(1);
    const [respuesta18, setrespuesta18]=useState(1);
    const [respuesta19, setrespuesta19]=useState(1);
    const [respuesta20, setrespuesta20]=useState(1);
    const [respuesta21, setrespuesta21]=useState(1);
    const [respuesta22, setrespuesta22]=useState(1);
    const [respuesta23, setrespuesta23]=useState(1);
    const [respuesta24, setrespuesta24]=useState(1);
    const [respuesta25, setrespuesta25]=useState(1);
    const [respuesta26, setrespuesta26]=useState(1);
    const [respuesta27, setrespuesta27]=useState(1);
    const [respuesta28, setrespuesta28]=useState(1);
    const [respuesta29, setrespuesta29]=useState(1);
    const [respuesta30, setrespuesta30]=useState(1);
    const [respuesta31, setrespuesta31]=useState(1);
    const [respuesta32, setrespuesta32]=useState(1);
    const [respuesta33, setrespuesta33]=useState(1);
    const [respuesta34, setrespuesta34]=useState(1);
    const [respuesta35, setrespuesta35]=useState(1);
    const [respuesta36, setrespuesta36]=useState(1);

    const preguntas={
        pregunta1: "duermo poco",
        pregunta10: "me cuesta demasiado despertarme",
        pregunta11: "ronco",
        pregunta12: "hablo dormido",
        pregunta13: "tengo molestia en las piernas que me impide dormirme",
        pregunta14: "me muevo demasiado estando dormido",
        pregunta15: "despierto muy cansado",
        pregunta16: "paralisis del sueño",
        pregunta17: "perdida del tono muscular estando despierto (cataplexia)",
        pregunta18: "tengo ataques de sueño durante el dia",
        pregunta19: "tengo pesadillas",
        pregunta2: "duermo mucho",
        pregunta20: "rechino los dientes dormido",
        pregunta21: "dolor de cabeza al despertar",
        pregunta22: "fatiga fisica",
        pregunta23: "fatiga mental",
        pregunta24: "problemas para concentrarse",
        pregunta25: "perdida de la memoria",
        pregunta26: "mal humor",
        pregunta27: "bajo rendimiento academico/ laboral",
        pregunta28: "descontrol del peso",
        pregunta29: "disminucion del deseo sexual",
        pregunta3: "me cuesta quedarme dormido",
        pregunta30: "aumento de la presion arterial",
        pregunta31: "aparicion de arritmias cardiacas",
        pregunta32: "ansiedad",
        pregunta33: "depresion",
        pregunta34: "despiertas con sensacion de ahogo durante la noche",
        pregunta35: "sudoracion nocturna",
        pregunta36: "desgaste dental",
        pregunta4: "me siento somnoliento en el dia",
        pregunta5: "me da sueño demasiado temprano",
        pregunta6: "me da sueño demasiado tarde",
        pregunta7: "me despierto varias veces durante la noche ",
        pregunta8: "me despierto durante la noche y demoro mas de 30 minutos en volver a dormirme",
        pregunta9: "sueño de mala calida"
    }
useEffect(()=>{
  if (pacientes.length>0) {
    setSelectPaciente(pacientes[0].idPaciente)
  }
setrespuesta1(1)
setrespuesta2(1)
setrespuesta3(1)
setrespuesta4(1)
setrespuesta5(1)
setrespuesta6(1)
setrespuesta7(1)
setrespuesta8(1)
setrespuesta9(1)
setrespuesta10(1)
setrespuesta11(1)
setrespuesta12(1)
setrespuesta13(1)
setrespuesta14(1)
setrespuesta15(1)
setrespuesta16(1)
setrespuesta17(1)
setrespuesta18(1)
setrespuesta19(1)
setrespuesta20(1)
setrespuesta21(1)
setrespuesta22(1)
setrespuesta23(1)
setrespuesta24(1)
setrespuesta25(1)
setrespuesta26(1)
setrespuesta27(1)
setrespuesta28(1)
setrespuesta29(1)
setrespuesta30(1)
setrespuesta31(1)
setrespuesta32(1)
setrespuesta33(1)
setrespuesta34(1)
setrespuesta35(1)
setrespuesta36(1)
},[viewModal])

    const registrarTest=async(e)=>{
        e.preventDefault();
        if (selectPaciente==='' ) {
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'Por favor selecciona un paciente',
                type:'error'
            })
           
            return;
        }
        const arrayPacienteSeleccionado=pacientes.filter((paciente)=>{
            return paciente.idPaciente===selectPaciente
        })
       
       
        try {
            
            
            crearTest({
                idPaciente:selectPaciente,
                nombrePaciente:arrayPacienteSeleccionado[0].nombre,
                respuesta1:respuesta1,
                respuesta2:respuesta2,
                respuesta3:respuesta3,
                respuesta4:respuesta4,
                respuesta5:respuesta5,
                respuesta6:respuesta6,
                respuesta7:respuesta7,
                respuesta8:respuesta8,
                respuesta9:respuesta9,
                respuesta10:respuesta10,
                respuesta11:respuesta11,
                respuesta12:respuesta12,
                respuesta13:respuesta13,
                respuesta14:respuesta14,
                respuesta15:respuesta15,
                respuesta16:respuesta16,
                respuesta17:respuesta17,
                respuesta18:respuesta18,
                respuesta19:respuesta19,
                respuesta20:respuesta20,
                respuesta21:respuesta21,
                respuesta22:respuesta22,
                respuesta23:respuesta23,
                respuesta24:respuesta24,
                respuesta25:respuesta25,
                respuesta26:respuesta26,
                respuesta27:respuesta27,
                respuesta28:respuesta28,
                respuesta29:respuesta29,
                respuesta30:respuesta30,
                respuesta31:respuesta31,
                respuesta32:respuesta32,
                respuesta33:respuesta33,
                respuesta34:respuesta34,
                respuesta35:respuesta35,
                respuesta36:respuesta36
                
            });
            setEstadoAlert(true);
            setMensajeAlert({
                mensajeAlert:'El Cliente se agregó correctamente',
                type:'success'
            })
            setViewModal(false);
            
        } catch (err) {
            setEstadoAlert(true);

           let mensaje;
            switch (err.code) {
                
                default:
                    mensaje="Ocurrió un error"
                    
                    break;
            }
            setMensajeAlert({
                mensajeAlert:mensaje,
                type:'error'
            })
        }
    }
    return ( 
        <>
        
        <Alert
            mensajeAlert={mensajeAlert.mensajeAlert}
            type={mensajeAlert.type}
            estadoAlert={estadoAlert}
            setEstadoAlert={setEstadoAlert}
            
            ></Alert>
        
        <Modal titulo={"Agregar nuevo Test"} estadoModal={viewModal} cambiarEstado={setViewModal} tamano={80}>
       <Form onSubmit={registrarTest}>
            <Row>
                <Column w={50} margin={'auto'}>
                <select onChange={(e)=>{setSelectPaciente(e.target.value)}}>
                    {pacientes.map((paciente)=>{
                        return(
                            <option key={paciente.idPaciente} value={paciente.idPaciente}>{paciente.nombre}</option>
                        )
                    })}
                </select>
                </Column>
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta1}
                   response={respuesta1}
                   name={'pregunta1'}
                   setrespuesta={setrespuesta1}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta2}
                   response={respuesta2}
                   name={'pregunta2'}
                   setrespuesta={setrespuesta2}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta3}
                   response={respuesta3}
                   name={'pregunta3'}
                   setrespuesta={setrespuesta3}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta4}
                   response={respuesta4}
                   name={'pregunta4'}
                   setrespuesta={setrespuesta4}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta5}
                   response={respuesta5}
                   name={'pregunta5'}
                   setrespuesta={setrespuesta5}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta6}
                   response={respuesta6}
                   name={'pregunta6'}
                   setrespuesta={setrespuesta6}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta7}
                   response={respuesta7}
                   name={'pregunta7'}
                   setrespuesta={setrespuesta7}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta8}
                   response={respuesta8}
                   name={'pregunta8'}
                   setrespuesta={setrespuesta8}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta9}
                   response={respuesta9}
                   name={'pregunta9'}
                   setrespuesta={setrespuesta9}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta10}
                   response={respuesta10}
                   name={'pregunta10'}
                   setrespuesta={setrespuesta10}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta11}
                   response={respuesta11}
                   name={'pregunta11'}
                   setrespuesta={setrespuesta11}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta12}
                   response={respuesta12}
                   name={'pregunta12'}
                   setrespuesta={setrespuesta12}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta13}
                   response={respuesta13}
                   name={'pregunta13'}
                   setrespuesta={setrespuesta13}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta14}
                   response={respuesta14}
                   name={'pregunta14'}
                   setrespuesta={setrespuesta14}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta15}
                   response={respuesta15}
                   name={'pregunta15'}
                   setrespuesta={setrespuesta15}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta16}
                   response={respuesta16}
                   name={'pregunta16'}
                   setrespuesta={setrespuesta16}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta17}
                   response={respuesta17}
                   name={'pregunta17'}
                   setrespuesta={setrespuesta17}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta18}
                   response={respuesta18}
                   name={'pregunta18'}
                   setrespuesta={setrespuesta18}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta19}
                   response={respuesta19}
                   name={'pregunta19'}
                   setrespuesta={setrespuesta19}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta20}
                   response={respuesta20}
                   name={'pregunta20'}
                   setrespuesta={setrespuesta20}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta21}
                   response={respuesta21}
                   name={'pregunta21'}
                   setrespuesta={setrespuesta21}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta22}
                   response={respuesta22}
                   name={'pregunta22'}
                   setrespuesta={setrespuesta22}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta23}
                   response={respuesta23}
                   name={'pregunta23'}
                   setrespuesta={setrespuesta23}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta24}
                   response={respuesta24}
                   name={'pregunta24'}
                   setrespuesta={setrespuesta24}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta25}
                   response={respuesta25}
                   name={'pregunta25'}
                   setrespuesta={setrespuesta25}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta26}
                   response={respuesta26}
                   name={'pregunta26'}
                   setrespuesta={setrespuesta26}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta27}
                   response={respuesta27}
                   name={'pregunta27'}
                   setrespuesta={setrespuesta27}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta28}
                   response={respuesta28}
                   name={'pregunta28'}
                   setrespuesta={setrespuesta28}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta29}
                   response={respuesta29}
                   name={'pregunta29'}
                   setrespuesta={setrespuesta29}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta30}
                   response={respuesta30}
                   name={'pregunta30'}
                   setrespuesta={setrespuesta30}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta31}
                   response={respuesta31}
                   name={'pregunta31'}
                   setrespuesta={setrespuesta31}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta32}
                   response={respuesta32}
                   name={'pregunta32'}
                   setrespuesta={setrespuesta32}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta33}
                   response={respuesta33}
                   name={'pregunta33'}
                   setrespuesta={setrespuesta33}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta34}
                   response={respuesta34}
                   name={'pregunta34'}
                   setrespuesta={setrespuesta34}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={95} margin={' 20px auto'} justifyContent={'space-between'} >
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta35}
                   response={respuesta35}
                   name={'pregunta35'}
                   setrespuesta={setrespuesta35}
                   ></ItemTest>
                   
                   </Column>
                <Column w={50}>
                   <ItemTest
                   pregunta={preguntas.pregunta36}
                   response={respuesta36}
                   name={'pregunta36'}
                   setrespuesta={setrespuesta36}
                   ></ItemTest>
                   
                   </Column>
                
            </Row>
            <Row w={50} margin={'auto'}>
            <Button size={'smallBlock'} margin={'2em 0'}>Modificar</Button>
            </Row>

        </Form>
        
    </Modal>
    </>
     );
}
 
export default ModalAgregarTest;