import { useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { collection, onSnapshot, query  } from "firebase/firestore";




const useGetPacientes = () => {

    const [ pacientes, setPacientes]=useState([]);

    useEffect(()=>{
        const consulta=query(
            collection(db, 'pacientes')
        )
       
            const unsub = onSnapshot(consulta, (snapshot) => {
                    const dataSnapShot=snapshot.docs.map((paciente)=>{
                      
                        return {...paciente.data(),idPaciente:paciente.id}
                            
                        
                    });
                    setPacientes(dataSnapShot) ;
            });
          
            
            return unsub;
           
    },[])
    return [pacientes];
}
 
export default useGetPacientes;