import System from '../../componentes/sistema';
import { UsuariosStyle, DivBuscador, DivTableUsuarios } from './style';
import { useState } from 'react';
import Buscador from '../../componentes/buscador';
import Tabla from '../../componentes/tabla';
import Row from '../../componentes/Row';
import Button from '../../componentes/button';
import useObtenerUsuarios from '../../hooks/useObtenerUsuarios';
import ModalAgregarUsuarios from './modalAgregarUsuarios';
import ModalEditarUsuarios from './modalEditarUsuarios';



const Usuarios = () => {
    const [usuarios] = useObtenerUsuarios();
    const [viewModal, setViewModal] = useState(false);
    const [idUsuarioEditar, SetidUsuarioEditar] = useState('');
    const [viewModalEditar, setViewModalEditar] = useState(false);
    
const viewModalEditarUsuario=(id)=>{
    SetidUsuarioEditar(id);
    setViewModalEditar(true);
}

    return (
        <>
            <ModalEditarUsuarios viewModalEditar={viewModalEditar} setViewModalEditar={setViewModalEditar} idUsuarioEditar={idUsuarioEditar} ></ModalEditarUsuarios>
            <ModalAgregarUsuarios viewModal={viewModal} setViewModal={setViewModal} ></ModalAgregarUsuarios>
            <System>

                <UsuariosStyle>
                    <Row justifyContent={'space-between'} alignItems={'end'} w={95} margin={'auto'} padding={'2em'}>
                        <DivBuscador>
                            <Buscador>

                            </Buscador>
                        </DivBuscador>
                        <Button onClick={() => { setViewModal(true) }} margin={0} type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            Agregar
                        </Button>
                    </Row>
                    <DivTableUsuarios>
                        <Tabla>
                            {usuarios.data.length > 0 ?
                                <>
                                    <thead>
                                        <tr><th>Nombre </th>
                                            <th>Correo </th>
                                            <th>Tipo de Usuario </th>
                                            <th>Estado</th>
                                            <th>Acciones </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usuarios.data.map((usuario)=>{
                                            return(
                                                <tr key={usuario[0]}>
                                                <td>{usuario[1]} </td>
                                                <td>{usuario[2]}</td>
                                                <td>{usuario[3]} </td>
                                                <td>{usuario[4]} </td>
                                                <td>
                                                    <div >
                                                        <button onClick={()=>{viewModalEditarUsuario(usuario[0])}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"></path></svg>
                                                        </button>
                                                        {/*
                                                        
                                                        <button>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"></path></svg>
                                                        </button>
                                                        */}
                                                    </div>
                                                </td>
                                            </tr>
                                            )
                                        })}
                                       
                                    </tbody>
                                </>
                                : <></>}
                        </Tabla>
                    </DivTableUsuarios>

                </UsuariosStyle>
            </System>
        </>
    );
}

export default Usuarios;