
import { HeaderStyles } from "./style";
import { auth } from "../../firebase/firebaseConfig";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Header = () => {
    const navigate=useNavigate();

    const cerrarSesion=async()=>{
        try {
            await  signOut(auth);
            navigate('/');
            
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <HeaderStyles>
            <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.56 55.6">
                    <path d="M333.31,432a22.31,22.31,0,0,1-5.14,8.05c-7.38,7.78-16.43,10.25-26.85,8.34a6.81,6.81,0,0,0-2.34,0c-10.93,2-20.32-.79-27.62-9.29a26.46,26.46,0,0,1-6.2-22.39,27.78,27.78,0,0,1,23.44-23,24.53,24.53,0,0,1,9.62.34,10.17,10.17,0,0,0,4.36-.06c11.53-1.93,20.77,1.95,27.82,11.16a27.15,27.15,0,0,1,2.43,4.23c.19.36-.79.89-1,.53-5.22-10.2-13.61-15.25-25-15.36a26.86,26.86,0,0,0-3.45.34c-.13,0-.25.15-.56.35l2.51,1.26A27.88,27.88,0,0,1,318,409.71c.4.82.09,1.08-.66,1.28a1.39,1.39,0,0,1-.47-.65c-2.7-6.53-7.84-10.53-14-13.59a5.46,5.46,0,0,0-5.61.2,22.73,22.73,0,0,0-10.35,12.65,34.81,34.81,0,0,0-1.35,18.25c1.14,6.47,3.87,12.23,9.44,16.18a37.2,37.2,0,0,0,4.83,2.59,1.33,1.33,0,0,0,.9,0,26.36,26.36,0,0,0,15.94-13.9c.27-.54.31-1.49,1.29-1.15a3.3,3.3,0,0,1-.26,1.62,27.4,27.4,0,0,1-13.91,13.54,1.81,1.81,0,0,0-.85.74c.49.1,1,.23,1.46.3a26.83,26.83,0,0,0,26.76-13.93,18,18,0,0,1,.93-1.8C332.33,431.71,333.2,431.86,333.31,432Zm-36,15.31c-10.86-5.37-17.4-13.81-17.36-26.09s6.67-20.89,17.84-26.13a2.65,2.65,0,0,0-.85-.21c-5.36-.78-10.53-.35-15.29,2.44-5.92,3.46-9.16,8.88-10.65,15.34a36.68,36.68,0,0,0,.54,19.11,23.19,23.19,0,0,0,7.58,11.62c5.27,4.29,11.44,4.86,17.89,4.12C297.07,447.51,297.15,447.4,297.27,447.32Z" transform="translate(-264.75 -393.42)"/>
            </svg>

            <button onClick={cerrarSesion}>Cerrar Sesión</button>
        </HeaderStyles>
    );
}

export default Header;
