import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
const crearPaciente = ({
    nombre,
    rut,
    correo,
    telefono,
    direccion,
    
}) => {
 
        return addDoc(collection(db, "pacientes"), {
            nombre:nombre,
            rut:rut,
            correo:correo,
            telefono:telefono,
            direccion:direccion,
            estado:'activo'
           
        });
        

}
 
export default crearPaciente;