import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import GlobalStyle from "./theme/GlobalStyle"


import {ProviderSession} from './context/contextSession'

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle/>
    
      <ProviderSession>
      <App />
      </ProviderSession>
    
  </React.StrictMode>,
  document.getElementById('root')
);


