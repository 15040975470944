import { Overlay, DivModal, HeaderModal, DivContentModal } from "./style";



const Modal = ({ children, titulo = "Modal", estadoModal, cambiarEstado, tamano }) => {
    return (
        <>
            {estadoModal &&
                <Overlay>
                    <DivModal tamano={tamano}>
                        <HeaderModal>
                            <div>{titulo}</div>
                            <button onClick={() => { cambiarEstado(false) }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg></button>
                        </HeaderModal>
                        <DivContentModal>
                            {children}
                        </DivContentModal>
                    </DivModal>
                </Overlay>
            }
        </>
    );
}

export default Modal;