import { db } from "./firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";


const actualizarPaciente = async ({ 
    idDoc,
    nombre,
    rut,
    correo,
    telefono,
    direccion
}) => {
    const documento = doc(db, "pacientes", idDoc);

    return await updateDoc(documento, {
        nombre:nombre,
        rut:rut,
        correo:correo,
        telefono:telefono,
        direccion:direccion
    });
}

export default actualizarPaciente;