
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/login';

import Usuarios from './pages/usuarios';
import RutaPrivada from './componentes/rutaPrivada';
import Pacientes from './pages/pacientes';
import TestDelSueno from './pages/testDelSueno';
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/usuarios" element={
          <RutaPrivada>
            <Usuarios />
          </RutaPrivada>
        } />
        <Route path="/pacientes" element={
          <RutaPrivada>
            <Pacientes />
          </RutaPrivada>
        } />
        <Route path="/test" element={
          <RutaPrivada>
            <TestDelSueno />
          </RutaPrivada>
        } />

      </Routes>
    </Router>
  );
}

export default App;



