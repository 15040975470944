import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import {doc, getDoc} from "firebase/firestore";

import { useNavigate } from "react-router-dom";

const useGetTestPorId  = (id) => {
   console.log(id)
    const [test, setTest]=useState('');
    const navigate=useNavigate();

    useEffect(()=>{
        if (id!=='') {
        const getTest=async()=>{
            const documento= await getDoc(doc(db,'tests',id));
            if (documento.exists) {
                setTest(documento)
            } else {
                navigate('/usuarios');
            }
        }
        getTest()
    }
    },[id,navigate])
    return [test];
}
 
export default useGetTestPorId ;