import { db } from "./firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
const crearTest = ({
idPaciente,
nombrePaciente,
respuesta1,
respuesta2,
respuesta3,
respuesta4,
respuesta5,
respuesta6,
respuesta7,
respuesta8,
respuesta9,
respuesta10,
respuesta11,
respuesta12,
respuesta13,
respuesta14,
respuesta15,
respuesta16,
respuesta17,
respuesta18,
respuesta19,
respuesta20,
respuesta21,
respuesta22,
respuesta23,
respuesta24,
respuesta25,
respuesta26,
respuesta27,
respuesta28,
respuesta29,
respuesta30,
respuesta31,
respuesta32,
respuesta33,
respuesta34,
respuesta35,
respuesta36

    
}) => {
 
        return addDoc(collection(db, "tests"), {
            idPaciente:idPaciente,
            nombrePaciente:nombrePaciente,
            respuesta1:respuesta1,
            respuesta2:respuesta2,
            respuesta3:respuesta3,
            respuesta4:respuesta4,
            respuesta5:respuesta5,
            respuesta6:respuesta6,
            respuesta7:respuesta7,
            respuesta8:respuesta8,
            respuesta9:respuesta9,
            respuesta10:respuesta10,
            respuesta11:respuesta11,
            respuesta12:respuesta12,
            respuesta13:respuesta13,
            respuesta14:respuesta14,
            respuesta15:respuesta15,
            respuesta16:respuesta16,
            respuesta17:respuesta17,
            respuesta18:respuesta18,
            respuesta19:respuesta19,
            respuesta20:respuesta20,
            respuesta21:respuesta21,
            respuesta22:respuesta22,
            respuesta23:respuesta23,
            respuesta24:respuesta24,
            respuesta25:respuesta25,
            respuesta26:respuesta26,
            respuesta27:respuesta27,
            respuesta28:respuesta28,
            respuesta29:respuesta29,
            respuesta30:respuesta30,
            respuesta31:respuesta31,
            respuesta32:respuesta32,
            respuesta33:respuesta33,
            respuesta34:respuesta34,
            respuesta35:respuesta35,
            respuesta36:respuesta36
           
        });
        

}
 
export default crearTest;