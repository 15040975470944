import { useState } from "react";
import { DivInputRadio } from "./style";

const ItemTest = ({pregunta,response,setrespuesta, name}) => {

    

    const cambiarEstado=(e)=>{
        setrespuesta(parseInt(e.target.value))
    }
    return ( 
        <DivInputRadio>
            <p>{pregunta}</p>
            <div>
             <input 
             type="radio"
             id="p1"
             name={name}
             value={1}
             checked={response===1?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p1">1</label>

             <input 
             type="radio"
             id="p2"
             name={name}
             value={2}
             checked={response===2?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p2">2</label>

             <input 
             type="radio"
             id="p3"
             name={name}
             value={3}
             checked={response===3?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p3">3</label>

             <input 
             type="radio"
             id="p4"
             name={name}
             value={4}
             checked={response===4?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p4">4</label>

             <input 
             type="radio"
             id="p5"
             name={name}
             value={5}
             checked={response===5?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p5">5</label>

             <input 
             
             type="radio"
             id="p6"
             name={name}
             value={6}
             checked={response===6?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p6">6</label>

             <input 
             type="radio"
             id="p7"
             name={name}
             value={7}
             checked={response===7?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p7">7</label>

             <input 
             type="radio"
             id="p8"
             name={name}
             value={8}
             checked={response===8?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p8">8</label>

             <input 
             type="radio"
             id="p9"
             name={name}
             value={9}
             checked={response===9?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p9">9</label>

             <input 
             type="radio"
             id="p10"
             name={name}
             value={10}
             checked={response===10?true:false}
             onChange={cambiarEstado}
             >
            </input>
            <label htmlFor="p10">10</label>
            </div>
        </DivInputRadio>
     );
}
 
export default ItemTest;